import React from 'react';
import { Fade } from 'react-reveal';
import dataManagement from '../../../../images/Db-management.png';
import intellisenceSecurity from '../../../../images/intelisence-security.png';
import serverStorage from '../../../../images/Server-storage.jpg';

const ICTSolution = () => {
    return (
        <section class="">
            <div class="max-w-screen-xl mx-auto">

                {/* All Content Wrapper Start */}
                <div class="content_wrapper p-3">
                    {/* ICT Solution Details Here */}
                    <Fade bottom duration={1000}>
                        <div>
                            <h2 class="text-3xl text-center cursor-pointer text-gray-200 my-10">ICT <span class="text-sky-500">ソリューション</span></h2>
                        </div>
                    </Fade>
                    <Fade bottom duration={1000}>
                        <div>
                            <p class="text-gray-400 text-justify text-base">グローバルなビジネスの中で培った技術と経験をもとに、私たちのICTは独自のユニークな洞察力に裏打ちされたサポートと洗練されたグローバルな技術パートナーのポートフォリオを戦略的にビジネスプロセスに生かしています。私たちは、CA Technologies、Cisco、Dell、F5、Fortinet、HP、IBM、Juniper, NetApp、VMwareなど主要 メーカーのパートナーとして世界中約50社のデータセンター、通信、コラボレーション、およびマネージドサービスに専門知識を提供しています。</p>
                        </div>
                    </Fade>

                    {/* Service storage Management Content Start */}
                    <div class="grid lg:grid-cols-2 gap-6 pt-24 items-center" id="server-management">
                        <Fade bottom duration={1000}>
                            <div>
                                <h2 class="text-3xl mb-10 text-center md:text-left text-gray-200">サーバー＆ストレージ</h2>
                                <p class="text-gray-400 text-base text-justify">HP、Del等のサーバーシステム仮想化技術を導入した設計</p>
                                <p class="text-gray-400 text-base text-justify py-2">ITソリューションの管理における、ストレージ・ターゲット、ストレージ・インターコネクト、またグローバルストレージ、またホストソフトウェア及びプラットフォームエンジニアリングなどの先進技術を活用することで、多くのコンピューティング企業とバリューチェーンで、複雑なプロジェクトを常にリードしてきました。更に弊社では独自のフレームワーク：（MASCOT：マネージドアプリケーションサービス）を活用し、品質、ガバナンス、変換、知識、テスト、プロジェクト管理、更には需要管理など、高度な業務プロセス管理を通じ、運用コストの大幅な削減を実現しています。</p>
                               {/* <p class="text-gray-400 text-justify text-base">We have dedicated Centers of Excellence for accelerated delivery across VLSI design, hardware, firmware, system software/virtualization, and application development. Creating waves in server storage technology ,our engineers are working on various layers of storage software that run on business servers; on embedded storage software that runs on storage interconnect elements like switches, directors and routers; on embedded software that runs on SAN and NAS storage systems; and on storage applications and solutions that involve backup, restore, archive, retrieve and quick recovery.</p>
                                <p class="text-gray-400 text-justify py-2 text-base">Through our proprietary framework — Managed Application Services (MaSCoT), which manages processes such as quality, governance, transformation, knowledge, test, project, and demand management — we significantly reduce our customers’ operational costs.</p> */}
                            </div>
                        </Fade>

                        {/* Right Content Image */}
                        <div class="p-10 mt-0 lg:mt-20 mx-auto animate-pulse">
                            <img class="rounded border-4 border-sky-300" src={serverStorage} alt="" />
                        </div>
                    </div>

                    {/* Db Management Content Start */}
                    <div class="grid lg:grid-cols-2 gap-6 my-0 md:pt-20 mx-auto items-center" id="db-management">
                        <div class="animate-pulse">
                            <img class="rounded border-4 border-sky-300" src={dataManagement} alt="" />
                        </div>

                        {/* Right Content Start */}
                        <Fade bottom duration={1000}>
                            <div>
                                <h2 class="text-3xl text-center pl-10 md:text-left text-gray-200">データベース</h2>
                                <div class="p-10">
                                    <p class="text-gray-400 text-justify text-base">CRM、ERP、SFA等、様々な業務アプリケーションの裏で動くデータベース管理システム（DBMS)の開発及びメンテナンスに強みを持ち、様々な データベースシステムに</p>
                                    <p class="text-gray-400 text-justify py-2 text-base">おいて多くの実績があります。 商用：DB2、Microsoft SQL Server、Oracle オープンソース：Firebird、MongoDB、MySQL、PostgreSQL</p>
                                </div>
                            </div>
                        </Fade>                            
                    </div>

                    {/* Intellisense Security Content Start */}
                    <div class="grid md:grid-cols-2 gap-6 pt-20 items-center" id="intellisense-security">
                        <Fade bottom duration={1000}>
                            <div>
                                <h2 class="text-3xl my-10 text-center md:text-left text-gray-200">防犯セキュリティ</h2>
                                <p class="text-gray-400 text-justify text-base">インドテックは20年以上にわたりセキュリティおよび監視業務に従事してきました。私たちは現場での複数年の経験を生かし、あらゆるセキュリティ要件や問題について取り組むことができます。私たちはオフィスのアラームやCCTVシステムと大企業のお客様のためのトップレベルのシステム、製造施設、および政府や法執行機関に関してすべてのレベルでの経験を持っています。</p>
                                <p class="text-gray-400 text-justify py-2 text-base">私たちはクライアントのニーズを満たすために膨大な知識ベースを提供し、セキュリティサービスや機器の種類を提供するために必要な多くの分野を網羅しています。私たちは、ワイヤレス隠しビデオ製品と技術を含む隠されたビデオ監視の分野で専門家です。</p>
                                <p class="text-gray-400 text-justify text-base">私たちはトラックの両側を見えるように我々の業界で大きな利点である隠されたビデオ反監視技術と実践（検出およびリスニングデバイスとしてだけでなく、ビデオを含む隠されたバグを処分する能力）に専門知識を持ています。さらに私たちは製品やサービスラインに完全な生体認証システム/ソリューションを追加しました。</p>
                            </div>
                        </Fade>

                        {/* Right Content Image  */}
                        <div class="p-10 mt-0 lg:mt-20 animate-pulse">
                            <img class="rounded border-4 border-sky-300" src={intellisenceSecurity} alt="" />
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    );
};

export default ICTSolution;
import React from 'react';
import { Fade } from 'react-reveal';
import companyFormation from '../../../images/Company-formation.png';
import fdiMarketing from '../../../images/FDI-Marketing.png';
import operationManagement from '../../../images/Operation-management.png';
import './BusinessConsultancy';

const BusinessConsultancy = () => {
    return (
        <section>
            <div class="max-w-screen-xl mx-auto">

                {/* All Content Wrapper */}
                <div class="p-3">
                    {/* Business Consultancy Content start */}
                        <div>
                            <Fade bottom duration={1000}>
                                <div>
                                    <h2 class="text-3xl text-center cursor-pointer text-gray-200 my-10"> <span class="text-sky-500">ビジネス</span> コンサルティング</h2>
                                </div>
                            </Fade>
                            <Fade bottom duration={1000}>
                                <div>

                                    {/* <p class="text-gray-400 text-justify text-base">Our consortium of seasoned consultants positions itself as a dynamic and client-centric consultancy firm in the Offshore Business Development, Network Services and Solutions, ICT Solutions, Software Development, including a broad outsourcing portfolio in the IT industry.</p>
                                    <p class="text-gray-400 text-justify py-2 text-base">With our partner companies in Indian sub-continent we are equipped with years of programming, communications and IT experience accumulated in its talented programmers, designers and engineers.</p>
                                    <p class="text-gray-400 text-justify text-base">We strive in not only providing the most relevant programming and networking proposition to your business needs, but also help you connect with the right specialist for the job by providing quality outsourcing for IT functions of your business.</p>
                                    <p class="text-gray-400 text-justify py-2 text-base">Our team has vast industry experience that crosses many vertical markets like Automobile, Commercial & Federal financial Management, Financial Services and Mortgage Banking, HealthCare, Insurance, Telecommunications. Our agility and flexibility in customizing solutions to fit your business needs and testing the quality of those solutions in an end to end setup either manually or in an automated fashion is the main benefit to our clients. .</p>
                                    <p class="text-gray-400 text-justify text-base">With our depth of the knowledge and resources, we can field complete project teams or skilled individuals to augment your team quickly. With our responsive, flexible and measured approach, we’re sure to have a solution that fits your project scale in all terms of business considerations. Let us meet your challenge and help your organization meet its highest potential through our leveraging of IT assets and infrastructure.</p>
                                 */}
                                 <p class="text-gray-400 text-justify text-base">弊社はインド工科大学、ダッカ大学を卒業し、日本の大学への留学や日本企業へ就職した経験を持つ「IT技術者」「MBA取得者」により起業され、日本の代表的企業のマーケティングノウハウ、経営・マネージメントに豊富な経験を持つ日本人と共に経営されています。日本企業の考え方ややり方を十分に理解した上で現地での豊富なネットワークを生かし日本企業のビジネスを支援いたします。</p>
                                </div>
                            </Fade>
                        </div> 

                    {/* Fdi market Research Content start */}
                    <div class="grid md:grid-cols-2 gap-6 pt-20 items-center" id="fdi-market">
                        <Fade bottom duration={1000}>
                            <div>
                                <div>
                                    <h2 class="text-3xl mb-10 text-center md:text-left text-gray-200">FDI マーケティング支援</h2>
                                </div>
                                <p class="text-gray-400 text-justify text-base">マーケティング支援
現地のマーケティング情報の提供</p>
                                <p class="text-gray-400 py-2 text-justify text-base">・産業調査、市場調査
</p>
                                <p class="text-gray-400 text-justify text-base">・業界情報、業界規制などの実態調査
</p>
                                <p class="text-gray-400 text-justify text-base">・消費者調査</p>
                                <p class="text-gray-400 text-justify text-base">テストマーケティング、展示会出展支援、サンプリング実施</p> 
                                <p class="text-gray-400 text-justify text-base">現地視察のサポート</p>
                                <p class="text-gray-400 text-justify text-base">②事業計画の立案、コンサルティング</p>
                                <p class="text-gray-400 text-justify text-base">フィージビリティ調査の実施</p>
                            </div>
                        </Fade>

                        {/* Right Content Image */}
                        <div class="p-12 mx-auto mt-0 md:mt-16 animate-pulse">
                            <img class="rounded border-4 border-sky-300" src={fdiMarketing} alt="" />
                        </div>
                    </div>

                    {/* Company Formation Content Start */}
                    <div class="grid md:grid-cols-2 gap-6 items-center pt-6" id="company-formation">
                        <div class="p-16 animate-pulse">
                            <img class="rounded border-4 border-sky-300" src={companyFormation} alt="" />
                        </div>

                        {/* Right Content Start */}
                        <Fade bottom duration={1000}>
                            <div>
                                <h2 class="text-3xl my-10 px-10 text-center md:text-left text-gray-200">海外進出・会社設立支援</h2>
                                <div class="mt-12 px-10">
                                    <p class="text-gray-400 text-justify text-base">①事業開始に向けた支援</p>
                                    <p class="text-gray-400 text-justify py-2 text-base">・パートナー候補の企業情報の提供、紹介、コーディネート  </p>
                                    <p class="text-gray-400 text-justify py-2 text-base">・事業開始、営業活動、生産管理、資材調達に関する情報提供と支援</p>
                                    <p class="text-gray-400 text-justify py-2 text-base">・用地取得、賃借に当たっての行政手続き上の情報提供と支援</p>
                                    <p class="text-gray-400 text-justify py-2 text-base">・現地での企業会計、税制、知財管理、金融取引に関する情報提供と支援</p>
                                    <p class="text-gray-400 text-justify py-2 text-base">②法人設立に向けた支援</p>
                                    <p class="text-gray-400 text-justify py-2 text-base">・法人設立登記に向けた支援</p>
                                    <p class="text-gray-400 text-justify py-2 text-base">・レンタルオフィスなど活動拠点設定支援</p>
                                    <p class="text-gray-400 text-justify py-2 text-base">・赴任者の生活（住居、治安、医療、教育、日常生活）情報の提供と支援</p>
                                </div>
                            </div>
                        </Fade>
                    </div>

                    {/* Operation Management Content Start */}
                    <div class="grid md:grid-cols-2 gap-6 pt-20 items-center" id="operation-management">
                        <Fade bottom duration={1000}>
                            <div>
                                <h2 class="text-3xl text-center md:text-left text-gray-200">運営管理</h2>
                                <p class="text-gray-400 text-center md:text-left pt-8 text-base">以下のサービスを提供しています</p>
                                <p class="text-gray-400 text-center md:text-left py-2 text-base">現地でのマネージメント支援
                                </p>
                                
                                <h5 class="text-2xl my-4 text-center md:text-left text-gray-200">①現地企業情報</h5>
                                <ul class="text-gray-400 text-center md:text-left py-2 text-base">
                                    <li>– ・新規取引先信用情報</li>
                                    <li>– ・競合会社の実態調査</li>
                                    <li>– ・弁護士、会計士、税理士、金融機関などの紹介</li>
                                </ul>

                                <h5 class="text-2xl my-4 text-center md:text-left text-gray-200">②マネージメント支援</h5>
                                <ul class="text-gray-400 text-justify py-2 text-base">
                                    <li>– ・現地での求人、労務管理に関する情報提供</li>
                                    <li>– ・現地人従業員の教育・社員研修（現地＆日本）に関する情報提供と支</li>
                                    <li>– ・現地での事業戦略の達成のための課題抽出と解決へのロードマップの設定</li>
                                    <li>– ・ロードマップ達成のためのマネージメントノウハウの提供</li>
                                    <li>– ・経営効率の改善のためのICTソリューションの提案</li>
                                </ul>
                            </div>
                        </Fade>                     

                        {/* Right Content Image */}
                        <div class="p-12 mt-0 md:mt-16 animate-pulse">
                            <img class="rounded border-4 border-sky-300" src={operationManagement} alt="" />
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
    );
};

export default BusinessConsultancy; 
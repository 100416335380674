import React from 'react';
import { Fade } from 'react-reveal';
import appDevelopment from '../../../../images/Apps-development.png';
import posSolution from '../../../../images/Pos-solution.jpg';
import webDevelopment from '../../../../images/web-development.jpg';

const SoftwareDevelopment = () => {
    return (
        <section >
            <div class="max-w-screen-xl mx-auto">

                {/* All Content Wrapper Start */}
                <div class="p-3">
                    {/* Software Development Content Start */}
                    <Fade bottom duration={1000}>
                        <div>
                            <h2 class="text-3xl text-center cursor-pointer text-gray-200 my-10"><span class="text-sky-500">ソフトウエア</span> 開発</h2>
                        </div>
                    </Fade>
                    <Fade bottom duration={1000}>
                        <div>
                            <p class="text-gray-400 text-justify text-base">インドテックはITコンサルティング、オフショア番組サービスに焦点を当てた大手ソフトウェア開発のアウトソーシング会社です。 SAMソリューションはIT専門家としての20年の経験と効果的に最先</p>
                            <p class="text-gray-400 text-justify py-2 text-base">端の技術を使用することで、どんな複雑なプロジェクトを扱うにも適した品質プロセスを実現します</p>
                            <p class="text-gray-400 text-justify text-base">インドテックはアウトソーシング、ソフトウェア開発などのサービスとオフショアソフトウェア開発サービス＆ソリューションを提供するソフトウェア開発分野の会社です。当社のソフトウェア開</p>
                            <p class="text-gray-400 text-justify py-2 text-base">発サービスは、お客様のための新製品の設計と開発が含まれています。私たちは起業家、企業、スタートアップベンチャーへの良好な技術ソリューションを提供し、彼らのビジネスが最大化することに貢献しています。</p>
                            <p class="text-gray-400 text-justify text-base">私たちは完全なエンドツーエンドかつ費用対効果の高い企業にWebアプリケーション、モバイルアプリケーションおよび設計を含む先進的なインターネットプロジェクトにおけるオフショアソフトウェア開発ソリューションを提供しています。</p>
                        </div>
                    </Fade>

                    {/* Web Development Content Start */}
                    <div class="grid md:grid-cols-2 gap-6 pt-20 items-center" id="web-development">
                        <Fade bottom duration={1000}>
                            <div>
                                <h2 class="text-3xl mb-10 text-center md:text-left text-gray-200">ウェブ開発</h2>
                                <p class="text-gray-400 text-justify text-base">このモバイル時代には、印象的なウェブサイトのデザインはあなたの訪問者が使用しているデバイスに関係なく必須です。私たちは、業界で最も優秀なデザイナーやユニークな賞を受賞したデザイナーをウェブサイトのデザインに提供しています。私たちのパーソナライズされた計画およびパッケージは個々のニーズを満たしかつ予算内に収まるように調整されています。その結果があなたのサイトがすべての訪問者を感動させることをお約束いたします。</p>
                                <p class="text-gray-400 text-justify py-2 text-base">あなたはオンラインプレゼンスを活用するためにコヒーレント、美的ウェブサイトを求めていますか？</p>
                                <p class="text-gray-400 text-justify text-base">私たちはあなたのオンラインブランドを確立するためシャープ等の受賞歴のある最先端のウェブサイトを設計しています。当社のカスタムWebデザインサービスは、あなたに次を提供することができます：</p>
                                <p class="text-gray-400 text-justify py-2 text-base"> • 個々のWebデザインのニーズを満たしかつあなたの予算内に収まるように調整したパーソナライズ計画とパッケージを提供いたします。</p>
                                <p class="text-gray-400 text-justify text-base">•ユニークな賞を受賞した業界に最も精通した有能なWebデザイナーのデザインやレイアウトを提供いたします。</p>
                                <p class="text-gray-400 text-justify text-base">• ウェブサイトのデザインや再設計プロジェクトから効果的な変換までビジネスブログのデザインとソーシャルメディアの   プロファイル設計のすべてに至るまでのWebデザインサービスを提供いたします。</p>
                            </div>
                        </Fade>

                        {/* Right Content Image */}
                        <div class="p-10 mt-20 animate-pulse">
                            <img class="rounded border-4 border-sky-300" src={webDevelopment} alt="" />
                        </div>
                    </div>

                    {/* Apps Development Content Start */}
                    <div class="grid md:grid-cols-2 gap-6 pt-20 items-center" id="apps-development">
                        <div class=" mt-28 animate-bounce">
                            <img class="rounded border-4 border-sky-300" src={appDevelopment} alt="" />
                        </div>

                        {/* Right Content Start */}
                        <Fade bottom duration={1000}>
                            <div class="py-20">
                                <h2 class="text-3xl my-10 px-10 text-center md:text-left text-gray-200">アプリケーション開発</h2>
                                <div class="mx-10">
                                    <p class="text-gray-400 text-justify text-base">① 最先端のアプリケーション開発実績</p>
                                    <p class="text-gray-400 text-justify py-2 text-base">お客様のニーズに合わせ、最先端のプログラム言語（C、C#、Net、Java、PHP、Python）を使って開発された、私たちの開発ソフトやWEBアプ リケーションは、既に
多くのお客様から高い評価を戴いています。またロボット開発に欠かせないAI、IoT関連のソフト開発、更にWebサービスとの ＡＩ連携や活用支援など、多くの開発実績
があります。</p>
                                    <h5 class="text-lg text-gray-200 my-3">② フィンテック関連のアプリケーション開発に注力</h5>
                                    <p class="text-gray-400 text-justify text-base">仮想通貨の信頼性を支えるブロックチェーン技術により、アクセス集中や器材障害を排除した信頼性の高いソフト開発が可能になりました。私達は、 話題のブロックチェ
ーン等、フィンテック関連のアプリケーション開発にも注力し、お客様のあらゆる要望にお答えできる開発体制を構築しています。</p>
                                    <h5 class="text-lg text-gray-200 my-3">③ スマートフォン/タブレット向けアプリケーション開発</h5>
                                    <p class="text-gray-400 text-justify text-base">私たちは、iPhoneやAndroidなど最新のアプリケーション開発に多くの実績を持っています。一般向けの公開アプリはもちろん業務用アプリ開 発まで、ジャンルを
問わず多彩な開発が可能です。システム開発で蓄積した幅広いノウハウには定評があり、常にお客様のニーズを踏まえた最適 な技術とサービスをご提供できます。</p>
                                </div>
                            </div>
                        </Fade>
                    </div>

                    {/* Pos Solution Content Start */}
                    <div class="grid md:grid-cols-2 gap-6 pt-20 items-center" id="pos-solution">
                        <Fade bottom duration={1000}>
                            <div>
                                <h2 class="text-3xl my-10 text-center md:text-left text-gray-200">POS ソリューション</h2>
                                <p class="text-gray-400 text-justify text-base">インドテックのチームは、小売業者が日々直面している問題を理解しています。当社のスタッフはには小売業界出身者がおり、お客様の入力した情報に基づき小売業の現実の世界に有効に機能するシステムを設計し、開発しています。私たちのシステムはあなたのビジネスが必要とする更新や将来を予測するために日々革新されています。</p>
                                <p class="text-gray-400 text-justify py-2 text-base">私たちは従業員の訓練時間が非常に限られていることを知っています。私たちはスタッフが数分内で典型的な販売を扱うことができ、非常に使いやすいスマートベンダーを設計しています。実際には、考えうるほとんどすべての機能がわずか1キーストロークで実現しています。</p>
                                <p class="text-gray-400 text-justify text-base">最も洗練された取引とレポートに同じ容易さでアクセスすることができます。管理者または所有者として、あなたはスタッフがどのくらいのアクセスしているかを把握できます。これは、カスタマイズの高いシステムを廉価で持っているようなものです。</p>
                                <p class="text-gray-400 text-justify py-2 text-base">インドテックはスポーツ用品、ハウス用品、写真の現像、ハードウェア、書籍、ジュエリー、ファッションの贈答品、テレビ、ステレオ、靴、家電、音楽など在庫を必要とする小売環境のほぼすべてのタイプのビジネスに理想的なシステムを提供いたします。</p>
                            </div>
                        </Fade>

                        {/* Right Content Image */}
                        <div class="p-10 mt-0 md:mt-28 animate-pulse">
                            <img class="rounded border-4 border-sky-300" src={posSolution} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SoftwareDevelopment;
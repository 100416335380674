import emailjs from '@emailjs/browser';
import React from 'react';
import { Slide } from 'react-reveal';
import './Contact.css';

const Contact = () => {
    // const [captchaValid, setCaptchaValid] = useState(null);
    // const [userValid, setUserValid] = useState(false);

    // const captcha = useRef(null);

    //   This function is for Recaptcha
    // const handleOnChange = (e) => {
    //     if(captcha.current.getValue()) {
    //         setCaptchaValid(true);
    //     } 
    //     e.target.reset();
    // }

    // Handling email sender
    const  sendEmail = (e) => {
        e.preventDefault();

        // This is for google recaptcha start
        // if(captcha.current.getValue()) {
        //     setUserValid(true);
        //     setCaptchaValid(true);
        // } else {
        //     alert('Please check recaptcha')
        // }
        // This is for google recaptcha end
    
        emailjs.sendForm('service_eog97l5', 'template_zli7n2d', e.target, 'user_MeroBc5u7VmNBItDAfmXU' )
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset();
      }

    // When you click the button then refresh the page
    // const  refreshPage = () => {
    //     window.location.reload(false);
    //   }

    return (
        <section class="p-10">
            <div class="max-w-screen-xl mx-auto">
                <>
                    <h1 class="text-4xl text-gray-200 text-center">連絡先</h1>
                    <p class="text-center text-gray-400 my-2 m-10" >アトビット なら安心してご利用いただけます。正確なニーズが何であれ、当社の IT 専門知識で組織をさらに強化するために提供できるものを見てみましょう。ネットワーキング、ソフトウェア開発、ICT ソリューションのすべてについて、アトビット にお任せください。</p>
                </>

                <div class="grid lg:grid-cols-2 mt-10 gap-4 md:p-5 lg:p-10 items-center bg-gray-700 rounded-3xl">
                    {/* Contact Form Start */}
                    <Slide left duration={1000}>
                        <form className="p-8" onSubmit={sendEmail} >
                            <div className="p-12 mx-auto bg-gray-800 rounded-3xl">
                                <div className="input-form">
                                    <input type="text" className="form-control w-full h-14 p-3 rounded border border-slate-300 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-700 focus:ring-1" placeholder="あなたの名前" name="full-name" required/>
                                </div>

                                <div className="input-form py-5">
                                    <input type="email" className="form-control h-14 w-full  p-3 rounded border border-slate-300 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-700 focus:ring-1" placeholder="あなたの電子メール" name="email" required/>
                                </div>

                                <div className="input-form">
                                    <input type="subject" className="form-control w-full h-14 p-3 rounded border border-slate-300 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-700 focus:ring-1" placeholder="件名を書く" name="subject" required/>
                                </div>

                                <div className="text-area mt-5">
                                    <textarea className="form-control w-full p-3 rounded border border-slate-300 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-700 focus:ring-1" id="" rows="7" placeholder="あなたのメッセージ" name="Message" required></textarea>
                                </div>

                                {/* Google Recaptcha handling */}
                                {/* <div className="w-5 md:w-7 pt-3">
                                    <div class="resize-r">
                                        <ReCAPTCHA
                                            ref={captcha}
                                            // sitekey="6Lc06G0eAAAAAG4muULavGmQ9RhHYt6hOEGI-U4R"
                                            onChange={handleOnChange}
                                        />
                                    </div>
                                </div> */}

                                <div className="mt-4 text-center">
                                    <button type="submit" className="text-gray-900 text-lg cursor-pointer w-full h-14 transform duration-300 bg-gray-200 hover:bg-gray-500 hover:text-gray-200 rounded mb-10">
                                    メッセージを送る
                                    </button>
                                </div>
                            </div>
                        </form>
                    </Slide>

                    {/* Right Content in form */}
                    <Slide right duration={1000}>
                        <div class="mx-auto text-center ">
                            <div class="">
                                <h3 class="text-2xl md:text-3xl text-sky-400 font-medium pt-5 text-center">連絡する</h3>
                                <p class="w-10/12 text-gray-300 text-center mx-auto my-5">問題に直面していますか？さて、私たちはここで解決策を待っています!</p>
                            </div>
                            
                            {/* Address and Phone part */}
                            <div class="md:flex justify-evenly gap-6">
                                {/* Address part */}
                                <div class="mt-10 md:flex md:gap-4 items-center text-gray-300">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 bg-sky-700 p-1 rounded mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>
                                    </div>
                                    <div class="text-center md:text-left">
                                        <h4 class="text-sky-400 text-lg font-medium mt-1 md:my-0">
住所</h4>
                                        <p>コンコード・ファーハン・ビル</p>
                                        <p>道路 3、ブロックJ、バリダーラ</p>
                                        <p>ダッカ 1212</p>
                                        <p>+880–192–580–8240</p>
                                    </div>
                                </div>

                                {/* Phone part */}
                                <div class="my-10 md:flex md:gap-4 items-center justify-center text-gray-300">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 bg-sky-700 p-1 rounded mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                        </svg>
                                    </div>
                                    <div class="text-center md:text-left">
                                        <h4 class="text-sky-400 text-lg font-medium mt-1 md:my-0">電話</h4>
                                        <p>+8801671060576</p>
                                    </div>
                                </div>
                            </div>

                            {/* Email and Working */}
                            <div class="md:flex justify-evenly gap-10">
                                {/* Email part */}
                                <div class="my-10 md:flex gap-4 items-center justify-center text-gray-300">
                                    <div class="">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 bg-sky-700 p-1 rounded mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76" />
                                        </svg>
                                    </div>
                                    <div class="text-center md:text-left">
                                        <h4 class="text-sky-400 text-lg font-medium mt-1 md:my-0">Eメール</h4>
                                        <p>info@attrabit.net</p>
                                    </div>
                                </div>

                                {/* Working part */}
                                <div class="my-10 md:flex md:gap-4 items-center justify-center text-gray-300">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 bg-sky-700 p-1 rounded mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                    <div class="text-center md:text-left">
                                        <h4 class="text-sky-400 text-lg font-medium mt-1 md:my-0">労働時間</h4>
                                        <p>午前 9 時～午後 6 時 (月～土)</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Slide>
                </div>
            </div>
        </section>
    );
};

export default Contact;